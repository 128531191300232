import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom'
import Background from "../../assets/images/k.png"
const Banner = ({ bannerText }) => {
    const location = useLocation();

    if (location.pathname === "/") {
        return (
            <div id="banner">
                <div id="banner-k" style={{backgroundImage: `url(${Background})`,backgroundSize:"auto 100%",backgroundPositionX: "80%",backgroundPositionY:0,backgroundRepeat:"no-repeat",paddingTop:60,paddingRight:0}}>
                    <div className="container">
                        <div className="row">
                            <div className="eight columns">
                                <h1>Build optimal staff rosters in <em>seconds</em></h1>
                                <p>Kalivra Roster is an advanced web-based rostering solution that automatically builds fair and optimal staff rosters many week or months in advance.</p>
                                <p>Simply build templates of which roles are needed in each location for a given day and then Kalivra Roster will automatically allocate the best staff based on the rules specified. All in a matter of seconds.</p>
                            </div>
                            <div className="four columns free-30">
                                <Link to="/signup" className="cta trial">Free 30 Day Trial</Link>
                                <p>No credit card required</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div id="banner"  className="justtitle">
            <div id="banner-k" style={{backgroundImage: `url(${Background})`,backgroundSize:"auto 100%",backgroundPositionX: "80%",backgroundPositionY:0,backgroundRepeat:"no-repeat",paddingTop:60,paddingRight:0}}>
                <div className="container">
                    <div  className="row">
                        {/* <div className="two columns">&nbsp;</div> */}
                        <div style={{width:"100%"}} className="eight columns">
                            <h1>{bannerText}</h1>
                        </div>
                        <div className="two columns">&nbsp;</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner;