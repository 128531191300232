import React, { useState, useEffect } from "react"
import { Auth } from "aws-amplify"
import ChangePassword from "../../component/ChangePassword/ChangePassword"
import { Link, useHistory } from "react-router-dom"

function Login({ goto }) {
  const history = useHistory()
  const [email, setemail] = useState({ value: "", isValid: true })
  const [password, setpassword] = useState({ value: "", isValid: true })
  const [User, setUser] = useState({})
  const [loader, setloader] = useState(false)
  const [isUserAllowedToChangePassword, setIsUserAllowedToChangePassword] =
    useState(false)

  useEffect(() => {}, [User, isUserAllowedToChangePassword])

  const signIn = (e) => {
    e.preventDefault()

    if (
      !(email.isValid && password.value) ||
      email.value === "" ||
      password.value === ""
    ) {
      alert("Please fill all mandatory fields!")
      return
    }
    setloader(true)
    Auth.signIn(email.value, password.value)
      .then((user) => {
        setUser(user)
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          // the array of required attributes, e.g ['email', 'phone_number']
          // confirmNow(user)
          setloader(false)
          localStorage.setItem("oldpassword", password.value)
          setIsUserAllowedToChangePassword(true)
        } else {
          // other situations
          setloader(false)
          alert("Login successfull")
          history.push("/dashboard")
        }
      })
      .catch((e) => {
        setloader(false)
        console.log(e)
        alert(JSON.stringify(e.message))
      })
  }

  function checkInputValidity(value) {
    if (value) return true
    return false
  }

  return (
    <div>
      {isUserAllowedToChangePassword ? (
        <ChangePassword
          user={User}
          gotoSignIn={() => setIsUserAllowedToChangePassword(false)}
        />
      ) : (
        <div className="content">
          <div className="container">
            <div className="row">
              <div className="two columns">&nbsp;</div>
              <div className="eight columns">
                <div className="aform">
                  <form onSubmit={signIn}>
                    <label for="email">Email Address </label>
                    <input
                      style={{ borderColor: email.isValid ? "#dedede" : "red" }}
                      onFocus={(e) =>
                        setemail({
                          value: e.target.value,
                          isValid: checkInputValidity(email.value),
                        })
                      }
                      onChange={(e) =>
                        setemail({
                          value: e.target.value,
                          isValid: checkInputValidity(e.target.value),
                        })
                      }
                      value={email.value}
                      type="email"
                      name="email"
                      id="email"
                    />
                    <label for="password">Password </label>
                    <input
                      style={{
                        borderColor: password.isValid ? "#dedede" : "red",
                      }}
                      onFocus={(e) =>
                        setpassword({
                          value: e.target.value,
                          isValid: checkInputValidity(email.value),
                        })
                      }
                      onChange={(e) =>
                        setpassword({
                          value: e.target.value,
                          isValid: checkInputValidity(e.target.value),
                        })
                      }
                      value={password.value}
                      type="password"
                      name="password"
                      id="password"
                    />

                    <div className="radios">
                      <input
                        type="checkbox"
                        id="remember"
                        name="remember"
                        value="remember"
                      />
                      <label for="remember">Remember me</label>
                      <br />
                    </div>
                    {loader ? (
                      <div id="loader" className="loader"></div>
                    ) : (
                      <input
                        type="submit"
                        name="login"
                        id="login"
                        value="Login"
                      />
                    )}

                    <hr />
                  </form>
                  <p>
                    <a href="#">Forgot your password?</a>
                    <br />
                    <Link to="/signup">No account? Sign up here.</Link>
                  </p>
                </div>
              </div>
              <div className="two columns">&nbsp;</div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Login
