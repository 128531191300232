/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    // "aws_cognito_identity_pool_id": "ap-southeast-2:4df98e6e-1169-4385-bf29-efb1dd30f3c4",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_HOYfp9fg5",
    "aws_user_pools_web_client_id": "3tbb5u5nln3hdur8arr35fgg0c",
    // "authenticationFlowType": 'USER_PASSWORD_AUTH',
    // "oauth": {}
};


export default awsmobile;
