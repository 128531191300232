import { Link } from "react-router-dom";

const Pricing = () => {
    return (
        <div className="content pricing">
            <div className="wrapper">
                <div className="row">

                    <div className="three columns">
                        <div className="price-bx">
                            <div className="ttl t1">Trial</div>
                            <div className="item"><strong>1 - 50 staff</strong> per roster</div>
                            <div className="item"><strong>30 days</strong> max roster length</div>
                            <div className="item"><strong>Standard</strong> server speed</div>
                            <div className="item">Support by email</div>
                            <div className="item">-</div>
                            <div className="item">-</div>
                            <div className="item">-</div>
                            <div className="item"><strong>99.0%</strong> service level agreement</div>
                            <div  className="p-cta p-cta-first-three trial-cta">
                                <p>Free for 30 days</p>

                                
                                <Link  className="cta" to={{pathname: "/signup", state: "trial"}} >Sign up</Link>
                            </div>
                        </div>
                    </div>

                    <div className="three columns">
                        <div className="price-bx">
                            <div className="ttl t2">Mini</div>
                            <div className="item"><strong>1 - 10 staff</strong> per roster</div>
                            <div className="item"><strong>30 days</strong> max roster length</div>
                            <div className="item"><strong>Standard</strong> server speed</div>
                            <div className="item">Support by email</div>
                            <div className="item">-</div>
                            <div className="item">-</div>
                            <div className="item">-</div>
                            <div className="item"><strong>99.0%</strong> service level agreement</div>
                            <div   className="p-cta p-cta-first-three">
                                <p>$8 per month or $88 annually per staff member</p>
                                

                                <Link    className="cta" to={{pathname: "/signup", state: "mini"}} >Sign up</Link>

                            </div>
                        </div>
                    </div>

                    <div className="three columns most-popular">
                        <div className="price-bx">
                            <div className="ttl t3">Standard</div>
                            <div className="item"><strong>1 - 50 staff</strong> per roster</div>
                            <div className="item"><strong>90 days</strong> max roster length</div>
                            <div className="item"><strong>Standard</strong> server speed</div>
                            <div className="item">Support by email</div>
                            <div className="item">Support by phone</div>
                            <div className="item">-</div>
                            <div className="item">-</div>
                            
                            <div className="item"><strong>99.0%</strong> service level agreement</div>
                            <div   className="p-cta p-cta-first-three">
                                <p>$10 per month or $110 annually per staff member</p>
                                
                                
                                <Link   className="cta" to={{pathname: "/signup", state: "standard"}} >Sign up</Link>

                            </div>
                        </div>
                    </div>

                    <div className="three columns">
                        <div className="price-bx">
                            <div className="ttl t4">Pro</div>
                            <div className="item"><strong>Unlimited staff</strong> per roster</div>
                            <div className="item"><strong>180 days</strong> max roster length</div>
                            <div className="item"><strong>High</strong> server speed</div>
                            <div className="item">Support by email</div>
                            <div className="item">Support by phone</div>
                            <div className="item">Enhanced support</div>
                            <div className="item poa">Custom add-ons/integration (P.O.A.)</div>
                            <div className="item"><strong>99.9%</strong> service level agreement</div>
                            <div  className="p-cta" >
                                <p>$15 per month or $165 annually per staff member</p>
                               
                                <Link  className="cta" to={{pathname: "/signup", state: "pro"}} >Sign up</Link>

                            </div>
                        </div>
                    </div>

                </div>

                <div className="row">
                    <div className="twelve columns pricing-info">
                        <p>All prices are in United States dollars (USD) and exclude G.S.T.</p>
                        <p className="pricet">Pricing is based on the number of staff members that you include in your rosters. The account owner and roster managers are not charged for unless they also appear in a roster.
                            <br />Monthly plans are charged each month in advance, and pro rata adjusted for any change in the number of staff members.
                            <br />Annual plans are sold as concurrent licenses and are charged in advance. You may add and remove staff members throughout the year up to your license limit at no charge. If you exceed the limit you will be charged the pro rata monthly rate for any additional staff members.</p>
                        <h4>Have special requirements? Need a larger trial? Please <Link to="contact-us">contact us</Link>.</h4>
                    </div>
                </div>

            </div>
        </div >
    );
}

export default Pricing;