import { Link } from "react-router-dom";
import logo from "../../assets/images/kalivra-ars.png"

const Header = () => {
    return (
        <header>
            <div className="row">
                <div className="one-half column">
                    <div id="logo">
                        <Link to="/">
                            <img
                                src={logo}
                                alt="Kalivra Advanced Rostering Solutions"
                                width="184"
                                height="60" />
                        </Link>
                    </div>
                </div>
                <div className="one-half column topctas">
                    <Link
                        to="/signup"
                        className="cta trial">
                        Free 30 Day Trial
                    </Link>
                    <Link
                        to="/login"
                        className="cta login">
                        Login
                    </Link>
                </div>
            </div>
        </header>
    )
}

export default Header;