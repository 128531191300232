import React from "react"
import { Link } from "react-router-dom"
import SubmitImg from "../../assets/images/forms/modern/blue/button.png"
function Signup() {
  return (
    <div>
    
  

      <div className="content" style={{ marginTop: 30, marginRight:0,marginLeft:20,marginBottom:0,fontSize:"1.1em"}}>
        <div className="container">
          <div className="row">
            <div className="one columns">&nbsp;</div>
            <div className="ten columns">
              <h3>Thank you for your interest in Kalivra Roster.</h3>
              <p>
                We are currently undergoing Beta testing with select customers.
                If you are interested in joining the Beta programme, please
                contact <a href="mailto:sales@kalivra.com">sales@kalivra.com</a>
                .
              </p>
              <p>
                If you would like to join our mailing list, please sign up
                below.
              </p>
            </div>
            <div className="one columns">&nbsp;</div>
          </div>
        </div>
      </div>

      <div className="row greyrow">
        <div className="container">
          <div className="aform">
            <form
              method="post"
              className="af-form-wrapper"
              accept-charset="UTF-8"
              action="https://www.aweber.com/scripts/addlead.pl"
            >
              <div style={{display:"none"}}>
                <input
                  type="hidden"
                  name="meta_web_form_id"
                  value="137192006"
                />
                <input type="hidden" name="meta_split_id" value="" />
                <input type="hidden" name="listname" value="awlist5727630" />
                <input
                  type="hidden"
                  name="redirect"
                  value="https://www.aweber.com/thankyou-coi.htm?m=text"
                  id="redirect_26123a8dbac76a76cccedf413bcb4092"
                />

                <input
                  type="hidden"
                  name="meta_adtracking"
                  value="Kalivra Roster product info"
                />
                <input type="hidden" name="meta_message" value="1" />
                <input
                  type="hidden"
                  name="meta_required"
                  value="name,email,custom Organisation"
                />

                <input type="hidden" name="meta_tooltip" value="" />
              </div>
              <div id="af-form-137192006" className="af-form">
                <div id="af-body-137192006" className="af-body af-standards">
                  <div className="af-element">
                    <div className="bodyText">
                      <p style={{textAlign:'center'}}>
                        <span style={{
                            color: "#172b4d",
                            fontSize:24
                        }}>
                          Sign up for Kalivra Roster product information.
                        </span>
                      </p>
                    </div>
                    <div className="af-clear"></div>
                  </div>
                  <div className="af-element">
                    <label className="previewLabel" for="awf_field-108432782">
                      Name:{" "}
                    </label>
                    <div className="af-textWrap">
                      <input
                        id="awf_field-108432782"
                        type="text"
                        name="name"
                        className="text"
                        value=""
                        onfocus=" if (this.value == '') { this.value = ''; }"
                        onblur="if (this.value == '') { this.value='';} "
                        tabindex="500"
                      />
                    </div>
                    <div className="af-clear"></div>
                  </div>
                  <div className="af-element">
                    <label className="previewLabel" for="awf_field-108432783">
                      Email:{" "}
                    </label>
                    <div className="af-textWrap">
                      <input
                        className="text"
                        id="awf_field-108432783"
                        type="text"
                        name="email"
                        value=""
                        tabindex="501"
                        onfocus=" if (this.value == '') { this.value = ''; }"
                        onblur="if (this.value == '') { this.value='';} "
                      />
                    </div>
                    <div className="af-clear"></div>
                  </div>
                  <div className="af-element">
                    <label className="previewLabel" for="awf_field-108432784">
                      Company / Organisation name:
                    </label>
                    <div className="af-textWrap">
                      <input
                        type="text"
                        id="awf_field-108432784"
                        className="text"
                        name="custom Organisation"
                        value=""
                        onfocus=" if (this.value == '') { this.value = ''; }"
                        onblur="if (this.value == '') { this.value='';} "
                        tabindex="502"
                      />
                    </div>
                    <div className="af-clear"></div>
                  </div>
                  <div className="af-element buttonContainer">
                    <input
                      name="submit"
                      id="af-submit-image-137192006"
                      type="image"
                      className="image"
                      style={{background: 'none', maxWidth: '100%'}}
                      alt="Submit Form"
                      src={SubmitImg}
                      tabindex="503"
                    />
                    <div className="af-clear"></div>
                  </div>
                  <div
                    className="af-element privacyPolicy"
                    style={{textAlign:"center"}}
                  >
                    <p>
                      We respect your{" "}
                      <Link
                        title="Privacy Policy"
                       to="/permission"
                        target="_blank"
                        rel="nofollow"
                      >
                        email privacy
                      </Link>
                    </p>
                    <div className="af-clear"></div>
                  </div>
                </div>
                <div id="af-footer-137192006" className="af-footer">
                  <div className="bodyText">
                    <p>&nbsp;</p>
                  </div>
                </div>
              </div>
              <div style={{display:"none"}}>
                <img src="form/displays.htm.gif?id=jMzsjJxMDAxs" alt="" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Signup
