import React,{useState} from "react"
import { Auth } from 'aws-amplify';
import { useHistory } from "react-router-dom";



function ChangePassword({user,gotoSignIn}) {
    const history = useHistory()
    const [confirmpassword, setconfirmpassword] = useState({ value: "", isValid: true })
    const [newpassword, setnewpassword] = useState({ value: "", isValid: true })
    const [loader, setloader] = useState(false)


    function checkInputValidity(value) {
        if (value) return true;
        return false;
    }


    const confirmNow = (e) => {
        e.preventDefault()
        let oldpassword= localStorage.getItem("oldpassword")
        if(confirmpassword.value !== newpassword.value ){
            alert("Password did not match")
        }else if(newpassword.value === oldpassword){
                alert("New password should not be equals to old password.")
        }
        else {
            setloader(true)
            const { userAttributes } = user.challengeParam;
            Auth.completeNewPassword(
                user,               // the Cognito User Object
                newpassword.value,       // the new password
                // OPTIONAL, the required attributes
                {
                    email: userAttributes.email,
                    // phone_number: '1234567890'
                }
                ).then(user => {
                    // at this time the user is logged in if no MFA required
                    setloader(false)
                    console.log(user);
                    alert("Password changed successfully")
                    localStorage.removeItem("oldpassword")
                    history.push("/dashboard")

                }).catch(e => {
                    setloader(false)
                    console.log(e);
                });
            }
        }

    return (
        <div>
            <div className="content" >
                <div className="container">
                    <div className="row">
                        <div className="two columns">&nbsp;</div>
                        <div className="eight columns">
                            <div className="aform">
                                <form  onSubmit={confirmNow} >
                                    <h3>Please set new password</h3>
                                    <label for="email">Password</label>
                                    <input style={{ borderColor: newpassword.isValid ? '#dedede' : 'red' }} onFocus={() => setnewpassword({ isValid: checkInputValidity(newpassword.value) })} onChange={(e) => setnewpassword({ value: e.target.value, isValid: checkInputValidity(e.target.value) })} value={newpassword.value} type="password" name="email" id="email" />
                                    <label for="password">Confirm Password </label>
                                    <input style={{ borderColor: confirmpassword.isValid ? '#dedede' : 'red' }} onFocus={() => setconfirmpassword({ isValid: checkInputValidity(confirmpassword.value) })} onChange={(e) => setconfirmpassword({ value: e.target.value, isValid: checkInputValidity(e.target.value) })} value={confirmpassword.value} type="password" name="password" id="password" />


                                    {
                                        loader ?
                                            <div id="loader" className="loader"></div>
                                            : <input type="submit" name="Confirm" id="confirm" value="Confirm" />
                                    }

                                    <hr />
                                </form>
                              
                            </div>
                        </div>
                        <div className="two columns">&nbsp;</div>
                    </div>

                </div>
            </div>
        </div>

    )
}

export default ChangePassword