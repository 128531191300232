


const Dashboard = () => {
    return(
        <div className="content">
            <h1>Welcome to dashboard page.</h1>
        </div>
    )
}

export default Dashboard