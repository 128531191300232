import ex_case_study from "./../../assets/images/ex-case-study.jpg"
import button from "../../assets/images/forms/modern/blue/button.png"
import { useState } from "react";
import { Link } from "react-router-dom";

const Home = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [company, setCompany] = useState("");

    return (
        <div>
            <div className="content features">
                <div className="wrapper">
                    <div className="row">
                        <h2 className="feat">What are the benefits of using Kalivra Roster?</h2>
                    </div>
                    <div className="row">
                        <div className="one-third column">
                            <i className="fas fa-user-clock"></i>
                            <h4>Reduces admin time</h4>
                            <p>Building rosters is immensely complicated and frustrating. But Kalivra Roster can build your rosters in a tiny fraction of the time and with a minimum of effort.</p>
                        </div>
                        <div className="one-third column">
                            <i className="fas fa-calendar-check"></i>
                            <h4>Keep your organisation running</h4>
                            <p>Mistakes in rostering can have massive rippling effects on your organisation. We do the hard work to ensure that your rosters work efficiently.</p>
                        </div>
                        <div className="one-third column">
                            <i className="fas fa-donate"></i>
                            <h4>Analyse staff hours and costs</h4>
                            <p>Analyse how your staff worked, and what the cost will be, or optimise your rosters to minimise total cost.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="one-third column">
                            <i className="fas fa-business-time"></i>
                            <h4>Assist with granting leave</h4>
                            <p>When you grant leave you don’t want to be left short staffed or having to bring in expensive cover. We can show you what the effect of leave would be on your roster.</p>
                        </div>
                        <div className="one-third column">
                            <i className="fas fa-file-medical-alt"></i>
                            <h4>Manage sick leave fast</h4>
                            <p>When someone calls in sick at the last moment, you need to find a cover fast. We can recommend the best choices in moments.</p>
                        </div>
                        <div className="one-third column">
                            <i className="fas fa-file-signature"></i>
                            <h4>Keep your rosters compliant</h4>
                            <p>Don’t put yourself at risk from Health & Safety or by breaking contracts. See just how compliant your rosters are at all times.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="twelve columns">
                            <Link to="/product" className="cta moreinfo">View more Product Information</Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content case-study">
                <div className="wrapper">
                    <div className="row">
                        <div className="one-half column">
                            <div className="tag">Case Study</div>
                            <h2 className="art">NMDHB Anaesthetic department case study</h2>
                            <p>At Nelson Marlborough DHB we used to generate the quarterly anaesthetic on-call roster by hand which took several days of work. Since switching to Kalivra Roster the whole process has been reduced to less than 60 minutes, and most of that is me gathering up the information needed from my colleagues.</p>
                            <p><Link to="/cs-nmdhb-june20">Read Full Case Study</Link></p>
                        </div>
                        <div className="one-half column">
                            <img className="u-max-full-width" src={ex_case_study} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="content latest-news">
                <div className="wrapper">
                    <div className="row">
                        <div className="two columns">&nbsp;</div>
                        <div className="eight columns">
                            <div className="tag">Article</div>
                            <div className="postdate">13 Aug 2020</div>
                            <h3>How to allocate public holidays without anyone complaining (TLDR; you can’t)</h3>
                                <p>Holidays, from the perspective of ‘the one who writes the roster’. Public holidays are my spectre behind the wardrobe door, or my monster under the bed. The allocated schedule of names to dates has been written, rewritten, objected to and argued with. Fortunately, to date, staff have always shown up to work on the dates they were rostered to.</p>
                                <p><Link to="/bl-holidays-aug20">Read full article</Link></p>
                            </div>
                        <div className="two columns">&nbsp;</div>
                    </div>
                </div>
            </div>

            <div className="row greyrow">
                <div className="container">
                    <div className="aform">
                        {/* <form method="post" className="af-form-wrapper" accept-charset="UTF-8" action="https://www.aweber.com/scripts/addlead.pl"> */}
                        <form className="af-form-wrapper">
                            <div style={{ display: "none" }}>
                                <input type="hidden" name="meta_web_form_id" value="137192006" />
                                <input type="hidden" name="meta_split_id" value="" />
                                <input type="hidden" name="listname" value="awlist5727630" />
                                <input type="hidden" name="redirect" value="https://www.aweber.com/thankyou-coi.htm?m=text" id="redirect_26123a8dbac76a76cccedf413bcb4092" />
                                <input type="hidden" name="meta_adtracking" value="Kalivra Roster product info" />
                                <input type="hidden" name="meta_message" value="1" />
                                <input type="hidden" name="meta_required" value="name,email,custom Organisation" />
                                <input type="hidden" name="meta_tooltip" value="" />
                            </div>
                            <div id="af-form-137192006" className="af-form"><div id="af-body-137192006" className="af-body af-standards">
                                <div className="af-element">
                                    <div className="bodyText"><p style={{ textAlign: "center" }}><span style={{ color: '#172b4d', fontFamily: "-apple-system, system-ui, 'Segoe UI', Roboto, Oxygen, Ubuntu, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontSize: "24px" }}>Sign up for Kalivra Roster product information.</span></p></div><div className="af-clear"></div>
                                </div>
                                <div className="af-element">
                                    <label className="previewLabel" htmlFor="awf_field-108432782">Name: </label>
                                    <div className="af-textWrap">
                                        <input value={name} onChange={(e) => setName(e.target.value)} id="awf_field-108432782" type="text" name="name" className="text" tabIndex="500" />
                                    </div>
                                    <div className="af-clear"></div></div>
                                <div className="af-element">
                                    <label className="previewLabel" htmlFor="awf_field-108432783">Email: </label>
                                    <div className="af-textWrap"><input value={email} onChange={(e) => setEmail(e.target.value)} className="text" id="awf_field-108432783" type="text" name="email" tabIndex="501" />
                                    </div><div className="af-clear"></div>
                                </div>
                                <div className="af-element">
                                    <label className="previewLabel" htmlFor="awf_field-108432784">Company / Organisation name:</label>
                                    <div className="af-textWrap"><input value={company} onChange={(e) => setCompany(e.target.value)} type="text" id="awf_field-108432784" className="text" name="custom Organisation" tabIndex="502" /></div>
                                    <div className="af-clear"></div></div><div className="af-element buttonContainer">
                                    <input name="submit" id="af-submit-image-137192006" type="image" className="image" style={{ background: "none", maxWidth: '100%' }} alt="Submit Form" src={button} tabIndex="503" />
                                    <div className="af-clear"></div>
                                </div>
                                <div className="af-element privacyPolicy" style={{ textAlign: "center" }}><p>We respect your <Link title="Privacy Policy" to="/permission" target="_blank" rel="nofollow">email privacy</Link></p>
                                    <div className="af-clear"></div>
                                </div>
                            </div>
                                <div id="af-footer-137192006" className="af-footer"><div className="bodyText"><p>&nbsp;</p></div></div>
                            </div>
                            <div style={{ display: "none" }}><img src="form/displays.htm.gif?id=jMzsjJxMDAxs" alt="" /></div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;