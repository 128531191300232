import { useState } from "react"
import postData from "../../helper/post"
import styles from "./Contact.module.css"

const initialState = {
  value: "",
  isValid: true,
}
const Contact = () => {
  const [name, setName] = useState({ value: "", isValid: true })
  const [email, setEmail] = useState({ value: "", isValid: true })
  const [subject, setSubject] = useState({ value: "", isValid: true })
  const [enquiry, setEnquiry] = useState({ value: "", isValid: true })
  const [loader, setLoader] = useState(false)

  const clearState = () => {
    setName(initialState)
    setEmail(initialState)
    setEnquiry(initialState)
    setSubject(initialState)
  }

  const onSubmit = (e) => {
    e.preventDefault()

    if (
      !(name.isValid && email.isValid && subject.isValid && enquiry.isValid) ||
      name.value === "" ||
      email.value === "" ||
      subject.value === "" ||
      enquiry.value === ""
    ) {
      alert("Please fill all mandatory fields!")
      return
    }
    console.log("LINE NO 36", name, email, subject, enquiry)
    setLoader(true)
    postData(
      "https://xiz8wsqd8a.execute-api.ap-southeast-2.amazonaws.com/dev/email/contactUs",
      {
        email: email.value,
        name: name.value,
        content: enquiry.value,
        subject: subject.value,
        pageType: "contact",
      }
    )
      .then((data) => {
        setLoader(false)
        alert("Successfull")
        clearState()
      })
      .catch((err) => {
        setLoader(false)
        console.log(err)
        alert("Something Went Wrong, Please Try Again!")
        clearState()
      })
  }

  function checkInputValidity(value) {
    if (value) return true
    return false
  }

  return (
    <div
      className="content"
      style={{
        marginTop: "30px",
        marginRight: 0,
        marginBottom: "20px",
        marginLeft: 0,
        fontSize: "1.1em",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="twelve columns">
            <p>
              <strong>Phone:</strong> +64 3 538 0277
            </p>
            <p>
              <strong>For sales enquiries:</strong>{" "}
              <a href="mailto:sales@kalivra.com">sales@kalivra.com</a>
              <br />
              <strong>For general enquiries:</strong>{" "}
              <a href="mailto:info@kalivra.com">info@kalivra.com</a>
            </p>

            <p>
              <strong>For privacy enquiries in the EU:</strong>{" "}
              <a href="mailto:eu-privacy@kalivra.com">eu-privacy@kalivra.com</a>
              <br />
              <strong>For privacy enquiries in the UK:</strong>{" "}
              <a href="http://phpstack-109943-1285167.kalivra.com/uk-privacy@kalivra.com">
                uk-privacy@kalivra.com
              </a>
              <br />
              <strong>For privacy enquiries in rest of the world:</strong>{" "}
              <a href="mailto:privacy@kalivra.com">privacy@kalivra.com</a>
            </p>

            <p>
              <strong>Address:</strong>
              <br />P O Box 2458,
              <br />
              Stoke, Nelson 7011,
              <br />
              New Zealand
            </p>
            <h3>Make an Enquiry</h3>

            <div className="aform">
              <div id="error_message"></div>
              <form
                data-children-count="4"
                id="myForm"
                onSubmit={(e) => onSubmit(e)}
              >
                <label htmlFor="name">Name</label>
                <input
                  value={name.value}
                  style={{ borderColor: name.isValid ? "#dedede" : "red" }}
                  onChange={(e) =>
                    setName({
                      value: e.target.value,
                      isValid: checkInputValidity(e.target.value),
                    })
                  }
                  onFocus={(e) =>
                    setName({
                      value: e.target.value,
                      isValid: checkInputValidity(name.value),
                    })
                  }
                  type="text"
                  name="name"
                  id="name"
                  data-kwimpalastatus="alive"
                  data-kwimpalaid="1598995395554-0"
                />
                <label htmlFor="name">Email Address</label>
                <input
                  value={email.value}
                  style={{ borderColor: email.isValid ? "#dedede" : "red" }}
                  onChange={(e) =>
                    setEmail({
                      value: e.target.value,
                      isValid: checkInputValidity(e.target.value),
                    })
                  }
                  onFocus={(e) =>
                    setEmail({
                      value: e.target.value,
                      isValid: checkInputValidity(email.value),
                    })
                  }
                  type="email"
                  name="email"
                  id="email"
                  data-kwimpalastatus="alive"
                  data-kwimpalaid="1598995395554-1"
                />
                <label htmlFor="subj">Subject</label>
                <input
                  value={subject.value}
                  style={{ borderColor: subject.isValid ? "#dedede" : "red" }}
                  onChange={(e) =>
                    setSubject({
                      value: e.target.value,
                      isValid: checkInputValidity(e.target.value),
                    })
                  }
                  onFocus={(e) =>
                    setSubject({
                      value: e.target.value,
                      isValid: checkInputValidity(subject.value),
                    })
                  }
                  type="text"
                  name="subj"
                  id="subj"
                />
                <label htmlFor="more">Your enquiry</label>
                <textarea
                  value={enquiry.value}
                  style={{ borderColor: enquiry.isValid ? "#dedede" : "red" }}
                  onChange={(e) =>
                    setEnquiry({
                      value: e.target.value,
                      isValid: checkInputValidity(e.target.value),
                    })
                  }
                  onFocus={(e) =>
                    setEnquiry({
                      value: e.target.value,
                      isValid: checkInputValidity(enquiry.value),
                    })
                  }
                  id="more"
                  name="more"
                ></textarea>
                {loader ? (
                  <div id="loader" className="loader"></div>
                ) : (
                  <input type="submit" name="submit" id="submit" />
                )}

                <span className={styles.inputSpan1}></span>
                <span className={styles.inputSpan2}></span>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
