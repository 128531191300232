import React, {useEffect} from "react";
const Cs_nmdhb_june20 = () => {
  useEffect(() => {
    window.scrollTo(0,0);

  }, [])
  return (
    <div>
      <div
        className="content"
        style={{
          margin: 30,
          marginTop: 0,
          marginBottom: 20,
          marginRight: 0,
          fontSize: "1.1em",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="twelve columns">
                <br />
              <p>
                At Nelson Marlborough DHB we used to generate the quarterly
                anaesthetic on-call roster by hand which took several days of
                work. Since switching to Kalivra Roster the whole process has
                been reduced to less than 60 minutes, and most of that is me
                gathering up the information needed from my colleagues.
              </p>
              <p>
                I’m an anaesthetist working in the local public hospital. We
                work a mixture of day sessions and an on-call roster which has
                to balance out the overnight call fairly. It also has to take
                into account that people work different patterns during the week
                and have to work call around their dates of leave.
              </p>
              <p>
                There are different approaches to this – one, which other
                departments use, is to write a “rolling roster” allocating call
                to everyone in a very predictable fashion regardless of working
                patterns. If you are allocated an on-call night during a period
                of leave, you have to swap with a colleague. This requires
                finding a colleague that can not only work your call night, but
                also has a call night that you can work in exchange. This can
                lead to some very complicated three- and four-way swaps until
                something is worked out that fits with everyone.
              </p>
              <p>
                Another approach involves building the quarterly rosters by hand
                taking into account both the working pattern and therefore call
                night preferences, and periods of leave. When I took over
                rostering, I upgraded the previous system - manually shuffling
                pieces of paper - into an excel spreadsheet. I had several
                issues – it took a long time to enter everyone’s leave dates
                into the spreadsheet, the formula that flagged leave and call
                allocation clash wasn’t always reliable and it was taking all my
                non clinical time for two weeks every quarter to write it,
                rewrite it, balance the numbers worked over the quarter and the
                year to date, and then rewrite it to take into account changes
                in the leave and call requests during the two weeks that I had
                written it.
              </p>
              <p>
                In 2016 I switched to using Kalivra Roster, and it has made the
                whole process far easier and faster. I still need to enter the
                leave dates and check the nightly call preferences, which takes
                less than an hour for a department of 14 people. The software
                then generates a balanced roster in under a minute. I email it
                out for checking and, bar glaring errors in my data entry, it is
                usually acceptable to everyone. If there are glaring errors,
                rewriting it is simply a matter of checking dates and clicking
                the button again.
              </p>
              <p>
                I have far more non-clinical time now to use for all the
                practice improvement activities (education, audit, keeping my
                knowledge up to date) that were pushed to the back when I was
                sweating over a spreadsheet.
              </p>
              <p>
                I would definitely recommend this to anyone who has the
                thankless task of writing their departmental roster, it makes
                life much easier!
              </p>
              <p>
                <strong>Dr Catherine Ben MBBS FRCA</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Cs_nmdhb_june20
