import React, { useState } from "react";
import { Link } from "react-router-dom";

function Navbar() {
    const [topNavClassName, setTopNavClassName] = useState("topnav");

    function openMenu() {
        if (topNavClassName === "topnav") {
            setTopNavClassName("topnav responsive");
        } else {
            setTopNavClassName("topnav");
        }
    }

    return (
        <nav>
            <ul id="myTopNav" className={topNavClassName}>
                <li className="li-i">
                    <a href="#" className="icon" onClick={openMenu}>☰</a>
                </li>
                <li className="active">
                    <a href="#" className="icon" onClick={openMenu}>Menu</a>
                </li>
                <li>
                    <Link to="/">Home</Link>
                </li>
                <li>
                    <Link to="/product">Product Info</Link>
                </li>
                <li>
                    <Link to="/pricing">Pricing</Link>
                </li>
                <li>
                    <Link to="/blog">Blog</Link>
                </li>
                <li>
                    <Link to="/about-us">About Us</Link>
                </li>
                <li>
                    <Link to="/careers">Careers</Link>
                </li>
                <li>
                    <Link to="/support">Support</Link>
                </li>
                <li>
                    <Link to="/contact-us">Contact Us</Link>
                </li>
            </ul>
        </nav>
    )
}

export default Navbar;