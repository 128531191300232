import React, {useEffect} from "react"

const Bl_holidays_aug20 = () => {
  useEffect(() => {
    window.scrollTo(0,0);

  }, [])
  return (
    <div>
      <div
        class="content"
        style={{
          margin: 30,
          marginTop: 0,
          marginBottom: 20,
          marginRight: 0,
          fontSize: "1.1em",
        }}
      >
        <div class="container">
          <div class="row">
            <div class="twelve columns">
                <br />
              <p>
                Holidays, from the perspective of ‘the one who writes the
                roster’.
              </p>
              <p>
                Public holidays are my spectre behind the wardrobe door, or my
                monster under the bed. The allocated schedule of names to dates
                has been written, rewritten, objected to and argued with.
                Fortunately, to date, staff have always shown up to work on the
                dates they were rostered to.
              </p>
              <p>
                It’s tricky writing a roster that allocates holidays fairly
                among the staff while allowing the parents amongst them to all
                have leave at the same time, or allowing staff members who have
                family at some distance enough time off to spend holidays with
                their loved ones. Many people like to take leave over the school
                holidays and not just those with children.
              </p>
              <p>
                There are various different strategies to approach this, which I
                will go through in turn.
              </p>
              <h4>1. The First-come-first-served strategy.</h4>
              <p>
                This allows for people to book leave as and when they want, on
                the premise that whoever gets in first gets approved. The roster
                will have to be written allocating the work to whoever didn’t
                apply for their leave in time.
              </p>
              <p>
                Disadvantages – unfairly allocates holiday work to newly
                arriving staff, and those who are less organised.
              </p>
              <p>Advantages – your team may become meticulously organised!</p>
              <h4>2. The Sharing-is-Caring strategy.</h4>
              <p>
                Everyone who would like leave for a particular holiday applies
                for it. The applications are overseen by a manager who checks
                previous leave records and allocates the leave to those people
                who have not been able to take that holiday most recently.
              </p>
              <p>
                Disadvantages – additional work for the manager, and some people
                will complain.
              </p>
              <p>
                Advantages – spreads the holiday work more fairly around the
                department.
              </p>
              <h4>3. The Forewarned-is-Forearmed strategy.</h4>
              <p>
                The roster for all public holidays is populated well in advance,
                allocating work fairly around the department. This should result
                in everyone being allocated each public holiday once throughout
                the duration of the roster.
              </p>
              <p>
                Disadvantages – some people prefer working holidays in winter or
                summer, it’s a lot of work and has to be redone every time
                someone joins or leaves the roster.
              </p>
              <p>
                Advantages – nobody can claim they didn’t know they were meant
                to be working New Year’s Eve when the roster is published 10
                years out.
              </p>
              <h4>4. The Hands-Up strategy.</h4>
              <p>
                Asking for volunteers to work over holiday periods can be
                surprisingly rewarding. Many staff may want to work particular
                holiday periods for varying reasons, including better
                remuneration or the ability to take time off in lieu at a later
                date.
              </p>
              <p>
                Disadvantages – if nobody puts their hand up it’s usually the
                person who writes the roster who has to work it. That’s more
                work for me.
              </p>
              <p>
                Advantages – fewer complaints over when and how people are
                allocated their shifts.
              </p>
              <p>
                In practice, it is usually a combination of approaches that are
                used. A forward looking roster for popular holidays to make sure
                that work is shared equally and fairly, with an embargo on
                booking leave if you are rostered to work, allocation of leave
                for holidays based on who has had leave for them most recently
                and asking for volunteers as needed.{" "}
              </p>
              <p>
                Whatever you do, someone will complain. It’s well worth keeping
                records of who has worked when, who has been away when and how
                much everyone else has worked so that when the complaints come
                your way, you can point to the records and point out that
                everyone has to work them at some point!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Bl_holidays_aug20
