const About = () => {
  return (
    <div>
      <div className="content" style={{marginTop:30,marginRight:0, marginBottom: 20, marginLeft: 20 , fontSize:"1.1.em"}}>
        <div className="container">
          <div className="row">
            <div className="twelve columns">
              <h3>What's the story?</h3>
              <p>
                Kalivra was officially founded in 2019, but our story really
                began in 2016 when we were given the unenviable job of writing
                Nelson hospital's anaesthetics on-call rosters. The system we
                inherited was mostly hand written notes and a collection of
                Microsoft Excel sheets, but we knew right away that there must
                be a better way.
              </p>
              <p>
                First we moved entirely to Excel, especially to automatically
                check if people were available. But that was slow and error
                prone. Before long we decided it was time to write custom roster
                solving software that could automatically search through
                millions of combinations to find the most workable solutions.
                The first version was slow, hard to use, looked terrible and was
                completely inflexible... But it solved in minutes what used to
                take days. A big step forward!
              </p>
              <p>
                When more and more hospitals and departments started asking for
                rostering help we realised two things: that there was a huge
                unmet demand, and that our software was far too inflexible. It
                seemed every department had their own special rostering needs,
                so we had to adapt and write general purpose rostering software
                that could solve almost anything.
              </p>
              <p>
                Two years later we had a web based service that was hundreds of
                times faster, far more flexible and much easier to use. By
                moving it to the web we could run rosters anywhere, and use
                cloud computing to scale to any problem size. Pretty neat, huh?
              </p>
              <h3>Where are we now?</h3>
              <p>
                Right now we are in Beta trials with several New Zealand
                hospitals. When we have the bugs and unplanned “features” worked
                out we will be offering the service to all New Zealand
                hospitals, both public and private. It shouldn't be long.
              </p>
              <h3>Where next?</h3>
              <p>
                As we add more and more features to the software we will be able
                to solve problems in other sectors such as retail, construction
                and emergency services.
              </p>
              <p>
                Need to plan when your vans get serviced but still maintain a
                service?
              </p>
              <p>
                Need to close down the head office for a few days but keep
                operations running in a smaller backup location?
              </p>
              <p>
                Need an electrician and builder to turn up on one day, the
                plasterer the following day, and the painter two days after
                that?
              </p>
              <p>
                We can (probably!) help with those sort of problems too. We
                would love to hear if you have an unusual rostering problem and
                how we can help.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
