import { Link } from "react-router-dom";

const Product = () => {
    return (
        <div>
            <div className="content features">
                <div className="wrapper">
                    <div className="row">
                        <div className="one-third column">
                            <i className="fas fa-magic"></i>
                            <h4>Auto-roster or tailor by hand</h4>
                            <p>Build rosters automatically, or drag 'n drop staff by hand. Or do both!</p>
                        </div>
                        <div className="one-third column">
                            <i className="far fa-window-restore"></i>
                            <h4>Allocate workload fairly and efficiently</h4>
                            <p>Nobody enjoys working a graveyard shift, but at least we can share the crummy shifts out fairly. Or just say it was our fault. We won't mind!</p>
                        </div>
                        <div className="one-third column">
                            <i className="fas fa-user-md"></i>
                            <h4>Quickly build up teams by role</h4>
                            <p>Need a team with a certain mix of skills? Leave it to us to work out the best people for the day.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="one-third column">
                            <i className="fas fa-clipboard-check"></i>
                            <h4>Approve leave with confidence</h4>
                            <p>Check days, weeks or months in advance to see if you have everything covered. Don't get blind-sided!</p>
                        </div>
                        <div className="one-third column">
                            <i className="fas fa-id-card-alt"></i>
                            <h4>Ensure legal compliance</h4>
                            <p>You set the roles you need, and we work out how to do it legally. Nobody wants to work 25 hours per day!</p>
                        </div>
                        <div className="one-third column">
                            <i className="fas fa-vial"></i>
                            <h4>Test new roster patterns</h4>
                            <p>Planning a new shift pattern? Too many moving parts to be sure if it will work? We can kick the tires for you.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="one-third column">
                            <i className="fas fa-redo-alt"></i>
                            <h4>Set repeating work patterns</h4>
                            <p>Your rosters follow a repeating pattern? That makes it easier for us both! But no problem if it changes every third Wednesday following every other blue moon either.</p>
                        </div>
                        <div className="one-third column">
                            <i className="fas fa-user-clock"></i>
                            <h4>Set staff leave and preferred days/hours of work</h4>
                            <p>If staff members are away on holiday, you can’t allocate them to a job. Obvious right? Yet it still happens on some other rosters.</p>
                        </div>
                        <div className="one-third column">
                            <i className="fas fa-sync-alt"></i>
                            <h4>Rotate staff through different roles</h4>
                            <p>Have some roles that you want to share out? No problem. Just set the role to rotate through staff that are qualified for it.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="one-third column">
                            <i className="fas fa-users"></i>
                            <h4>Group up staff that work well together</h4>
                            <p>Got a team that can read each others’ minds? We can try keep them together. Got people that throw toys at each other? We can keep them apart.</p>
                        </div>
                        <div className="one-third column">
                            <i className="fas fa-user-tag"></i>
                            <h4>Set optional team roles such as trainees</h4>
                            <p>Hmm. Where do I put the trainee this week? I know, ask Kalivra Roster. Simple!</p>
                        </div>
                        <div className="one-third column">
                            <i className="fas fa-street-view"></i>
                            <h4>Set staff location and territory covered</h4>
                            <p>Planes, trains and auto mobiles. Some jobs need people to move - we can handle that too.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="two columns">&nbsp;</div>
                        <div className="four columns">
                            <i className="fas fa-donate"></i>
                            <h4>Allocate work to minimise cost</h4>
                            <p>Different staff on different pay rates? Working out the monthly cost can get complicated. Let us do the maths for you.</p>
                        </div>
                        <div className="four columns">
                            <i className="fas fa-globe-asia"></i>
                            <h4>Supports staff in multiple time-zones</h4>
                            <p>Need staff coverage in multiple time zones too? Synchronise watches in 3.. 2.. 1... Nah, we’ve got you covered. Even over daylight savings. Phew!</p>
                        </div>
                        <div className="two columns">&nbsp;</div>
                    </div>
                </div>
            </div>

            <div className="content" style={{ margin: '30px 0 20px 0', fontSize: '1.1em' }}>
                <div className="container">
                    <div className="row">
                        <div className="twelve columns">
                            <h3>How do I build rosters?</h3>
                            <p>You enter details of your staff, the work sessions needed and the rules for this roster. Kalivra Roster then automatically decides which person is best for each job on each day. When entering your staff you include details such as skills, qualifications, annual leave and their work preferences. When creating work sessions you enter the times, location and staff requirements. Sessions are built up using templates that can be easily moved around and repeated at any interval. In the rules section you specify information such as how to split work up between staff.</p>
                            <p>Kalivra Roster will then allocate staff to the sessions while trying to be as fair and optimal as possible. You can always make manual changes and experiment with different roster patterns.</p>
                            <p><strong>Example:</strong> You are building a roster for a hospital surgical department. The roster repeats every four weeks, although staff allocation will change due to annual leave. On each day you specify which type of surgery you want done in each operating theatre. For each surgical list you specify the staffing requirements; for instance you might want one surgeon, one anaethetist, three nurses and so on. You can manually pick an exact person for each role, or you can just specify the skills and qualifications needed and let Kalivra Roster do the hard work of optimising your roster.</p>
                            <h3>Manage rapid staff changes</h3>
                            <p>Roster changes are almost inevitable - Staff book leave, call in sick, or sometimes just fail to turn up.</p>
                            <p>For this reason Kalivra Roster is built to allow rapid changes. When a gap is detected Kalivra Roster makes suggestions as to how you can fix it and then sends a notification to the affected staff.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content calltoaction">
                <div className="wrapper">
                    <div className="row">
                        <div className="twelve columns">
                            <h2>Sign up today for a free, no-obligation trial</h2>
                            <Link to="/signup" className="cta trial">Free 30 Day Trial</Link>
                            <p>No credit card required</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Product;