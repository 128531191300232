import React, { useEffect, useRef, useState } from "react"
import AWS from "aws-sdk"
 import postData from "../../helper/post"
let bucketRegion = "ap-southeast-2"
let IdentityPoolId = "ap-southeast-2:4df98e6e-1169-4385-bf29-efb1dd30f3c4"
AWS.config.region = bucketRegion
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: IdentityPoolId,
})

const initialState = {
  value: "",
  isValid: true,
}
const Careers = () => {
  let API_URL =
    "https://xiz8wsqd8a.execute-api.ap-southeast-2.amazonaws.com/dev/email/career"
  let subject = "JOB_APPLICATION"
  const ref = useRef()
  const [name, setName] = useState({ value: "", isValid: true })
  const [email, setEmail] = useState({ value: "", isValid: true })
  const [position, setPosition] = useState({ value: "Sales", isValid: true })
  const [description, setDescription] = useState({ value: "", isValid: true })
  const [progress, setProgress] = useState(0)
  const [loader, setLoader] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [fileUploadResult, setfileUploadResult] = useState(null)
  const clearState = () => {
    setName(initialState)
    setEmail(initialState)
    setPosition(initialState)
    setDescription(initialState)
    setProgress(0)
    setfileUploadResult(null)
    ref.current.value = ""
  }

  useEffect(() => {}, [progress])

  function handleFiles(e) {
    let file = e.target.files[0]
    let fileName = file.name
    let cvBucketName = "roster-file/CV"
    const params = {
      Bucket: cvBucketName,
      Key: fileName,
      Body: file,
    }

    setIsDisabled(true)
    const bucket = new AWS.S3({ params })
    bucket
      .upload(params, function (err, data) {
        if (err) {
          setIsDisabled(false)
          alert(err)
          return
        } else {
          setfileUploadResult(data)
          setIsDisabled(false)
          alert("Successfully Uploaded Your File!")
        }
      })
      .on("httpUploadProgress", function (progress) {
        let uploaded = parseInt((progress.loaded * 100) / progress.total)
        console.log(
          "uploaddedd ----- ",
          uploaded,
          progress.total,
          progress.loaded
        )
        handleProgressbar(uploaded)
        setIsDisabled(false)
      })

    function handleProgressbar(uploaded) {
      setProgress(uploaded)
    }
  }

  const onSubmit = (event) => {
    event.preventDefault()
    if (
      !(name.isValid && email.isValid && description.isValid) ||
      name.value === "" ||
      email.value === "" ||
      description.value === ""
    ) {
      console.log(description)
      alert("Please fill all mandatory fields!")
      return
    } else if (fileUploadResult === null) {
      alert("Please upload your CV.")
      return
    }

    setLoader(true)
    let body = {
      name: name.value,
      email: email.value,
      position: position.value,
      content: description.value,
      pageType: "career",
      subject: subject,
      attachmentKeyS3: fileUploadResult.Key,
    }
    postData(API_URL, body)
      .then((data) => {
        setLoader(false)
        alert("Successfull")
        clearState()
      })
      .catch((err) => {
        console.log(err)
        setLoader(false)
        alert("Something Went Wrong, Please Try Again!")
        clearState()
      })
  }

  function checkInputValidity(value) {
    if (value) return true
    return false
  }

  return (
    <div>
      <div
        className="content"
        style={{
          marginTop: 30,
          marginRight: 0,
          marginBottom: 20,
          marginLeft: 20,
          fontSize: "1.1.em",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="twelve columns">
              <p>
                We are looking htmlFor motivated and innovative staff to drive
                us towards being the world’s best rostering system. We have the
                vision. Do you have what we need to build, run, sell and support
                our software?
              </p>
              <p>
                We offer a competitive above-market salary, plus super, health
                insurance and other benefits. We encourage continuous training
                and improvement. Part-time, and flex-time options are available.
                Most roles can telework from anywhere in New Zealand. We treat
                our staff well.
              </p>
              <p>
                We are based in Nelson, New Zealand. While you can telework from
                almost anywhere, Nelson is one amazing city to live in. Sunny
                beaches, biking tracks, native forests to explore and ski slopes
                - all within a day’s drive. It’s a great place to get your
                work-life balance right.
              </p>
              <p>
                If you are interested in one of the roles listed below, please
                send in your CV htmlFor consideration.
              </p>
              <h4>Current Positions</h4>
              <p>
                <strong>Sales:</strong> inbound, outbound and direct. Travel
                required. Mid-level or Senior.
                <br />
                <strong>Customer services, support &amp; admin:</strong> CRM,
                billing, telephony, technical writing, office admin. Junior,
                Mid-level or Senior.
                <br />
                <strong>IT operations:</strong> Ubuntu/Linux admin, Database
                admin, AWS admin (Cloudwatch etc), Information Security. Junior,
                Mid-level or Senior.
                <br />
                <strong>IT support:</strong> Windows, Linux, MS &amp; Libra
                Office, PC/Mac hardware, network hardware, iOS/Android
                smartphone support, SIPS. Junior, Mid-level or Senior.
              </p>
              <p>
                <strong>Front end designer:</strong> HTML/CSS, UI/UX design,
                iOS/Android app, Photoshop/Illustrator. Junior, Mid-level or
                Senior.
                <br />
                <strong>Front end developer:</strong> HTML,
                Javascript/Truescript, Ajax(JSON), Websockets/REST API. Junior,
                Mid-level or Senior.
                <br />
                <strong>Cloud developer:</strong>
                Ubuntu/Linux, MySQL, AWS (Route 53, EC2, Cognito, Lambda,
                Aurora, DynamoDB, Cloudfront, Cloudwatch etc), Node.js,
                Websockets, Information Security. Mid-level or Senior.
                <br />
                <strong>Back end developer:</strong> C++, CUDA/OpenCL,
                AI/algorithmic development. Senior developer
              </p>
              <h4>To apply</h4>
              <p>
                Please fill out the following form to make an application
                htmlFor any of these roles.
              </p>
              <div className="aform">
                <div id="error_message"></div>
                <form
                  onSubmit={(e) => onSubmit(e)}
                  data-children-count="5"
                  id="myForm"
                >
                  <label htmlFor="name">Name</label>
                  <input
                    value={name.value}
                    onChange={(e) =>
                      setName({
                        value: e.target.value,
                        isValid: checkInputValidity(e.target.value),
                      })
                    }
                    onFocus={(e) =>
                      setName({
                        value: e.target.value,
                        isValid: checkInputValidity(name.value),
                      })
                    }
                    style={{ borderColor: name.isValid ? "#dedede" : "red" }}
                    type="text"
                    name="name"
                    id="name"
                    data-kwimpalastatus="alive"
                    data-kwimpalaid="1601235500213-0"
                  />
                  <label htmlFor="name">Email Address</label>
                  <input
                    value={email.value}
                    onChange={(e) =>
                      setEmail({
                        value: e.target.value,
                        isValid: checkInputValidity(e.target.value),
                      })
                    }
                    onFocus={(e) =>
                      setEmail({
                        value: e.target.value,
                        isValid: checkInputValidity(email.value),
                      })
                    }
                    style={{ borderColor: email.isValid ? "#dedede" : "red" }}
                    type="email"
                    name="email"
                    id="email"
                    data-kwimpalastatus="alive"
                    data-kwimpalaid="1601235500213-1"
                  />
                  <label htmlFor="jobt">Position</label>
                  <select
                    // value={position.value}
                    onChange={(e) =>
                      setPosition({
                        value: e.target.value,
                        isValid: checkInputValidity(e.target.value),
                      })
                    }
                    defaultValue={position.value}
                    id="position"
                    htmlFor="jobt"
                  >
                    <option value="Sales">Sales</option>
                    <option value="Customer services">
                      {" "}
                      Customer services
                    </option>
                    <option value="IT operations">IT operations</option>
                    <option value="IT support">IT support</option>
                    <option value="Front end designer">
                      Front end designer
                    </option>
                    <option value="Front end developer">
                      Front end developer
                    </option>
                    <option value="Cloud developer">Cloud developer</option>
                    <option value="Backend developer">Backend developer</option>
                  </select>
                  <label htmlFor="cv">Upload your C.V.</label>
                  <input
                    type="file"
                    ref={ref}
                    onChange={(e) => handleFiles(e)}
                    id="cv"
                    name="cv"
                    accept=".doc,.docx,.pdf,.odt,.odf,.txt,.rtf"
                  />
                  {progress > 0 && (
                    <progress id="progress" value={progress} max="100">
                      {progress.toString()}
                    </progress>
                  )}
                  <label htmlFor="more">
                    Anything else you would like to add
                  </label>
                  <textarea
                    value={description.value}
                    style={{
                      borderColor: description.isValid ? "#dedede" : "red",
                    }}
                    onChange={(e) =>
                      setDescription({
                        value: e.target.value,
                        isValid: checkInputValidity(e.target.value),
                      })
                    }
                    onFocus={(e) =>
                      setDescription({
                        value: e.target.value,
                        isValid: checkInputValidity(description.value),
                      })
                    }
                    id="more"
                    name="more"
                  ></textarea>

                  {loader ? (
                    <div id="loader" className="loader"></div>
                  ) : (
                    <input
                      type="submit"
                      disabled={isDisabled}
                      name="submit"
                      id="submit"
                    />
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Careers
