import React, {useEffect} from "react";
import { Link } from "react-router-dom";
const Permission = () => {
  useEffect(() => {
    window.scrollTo(0,0);

  }, [])
  return (
    <div>
        
       <main class="permissions-wrapper steel">
        <div class="permissions-wrapper-row">
            <Link to="/"><img src="https://assets.aweber-static.com/www/assets/img/aw-logo.8e90030e.png" alt="AWeber"
                    width="210" /></Link>
            <h1>Subscribe With Confidence.</h1>
            <h2>AWeber's PermissionPledge &#8482; Protects Your Privacy and Inbox.</h2>
        </div>
        <div class="permissions-wrapper-row">
            <img src="https://assets.aweber-static.com/www/assets/img/lock.9a85b528.svg" width="70" height="auto"
                alt="Secure Website Lock" /> 
            <h3>What Does This Mean?</h3>
            <p>This website belongs to someone who uses AWeber to responsibly manage their <Link to="/">email
                    marketing</Link> campaign</p>
            <ul>
                <li>They will send you email <strong>if you give them permission</strong> to do so (by subscribing to
                    their mailing list). They will <strong>respect your permission</strong>.</li>
                <li>They will only send you email that is <strong>related to what you subscribed to</strong> and why you
                    subscribed.</li>
                <li>They will abide by AWeber's anti-spam rules and <a href="antispam.htm">anti-spam</a> laws like <a
                        href="http://www.business.ftc.gov/documents/bus61-can-spam-act-compliance-guide-business">CAN-SPAM</a>.
                </li>
                <li>They will abide by AWeber’s <a href="/privacy.htm">Privacy Policy</a> and <a href="/dpst.htm">Data
                        Processing Terms</a>.</li>
                <li>You can <strong>unsubscribe whenever you want</strong> with a click of your mouse.</li>
            </ul>
        </div>
        <hr />
        <div class="permissions-wrapper-row">
            <h3>"What If I Want To Unsubscribe?"</h3>
            <p>At the end of every email you receive from this website you will see an <strong>unsubscribe
                    link</strong>.</p>
            <p>Click on that link and you will see an unsubscribe page with <strong>two columns marked "Subscribed" and
                    "Unsubscribed"</strong>.</p>
            <p>Click on the button in the "Unsubscribed" column. <strong>We will immediately unsubscribe you!</strong>
            </p>
        </div>
        <hr />
        <div class="permissions-wrapper-row">
            <h3>How Does AWeber Prevent Spam and Protect Inboxes?</h3>
            <ul>
                <li>We review customers' mailing lists with a variety of automatic tests.</li>
                <li><strong>Our staff manually reviews mailing lists </strong>for any signs of irresponsible emailing.
                </li>
            </ul>
            <p>This two-pronged approach has helped keep spammers off AWeber ever since <strong>we started managing
                    email in 1998</strong>.</p>
            <p>To make sure we can keep them off, we share ideas with others in several <atrong>email industry and
                    anti-spam groups</atrong> like the <a href="http://www.maawg.org/">Messaging Anti-Abuse Working
                    Group</a>, the <a href="http://www.antiphishing.org/">Anti-Phishing Working Group</a> and the <a
                    href="https://www.otalliance.org/">Online Trust Alliance</a>.</p>
        </div>
        <hr />
        <div class="permissions-wrapper-row center">
            <h2>Report Abuse &#8211; Help Us Stamp Out Spam!</h2>
            <p>If you feel you have received spam from an AWeber user, please <a href="mailto:abuse@aweber.com">report
                    it to our Anti-Abuse Team</a> immediately. You can email us at <a
                    href="mailto:abuse@aweber.com">abuse@aweber.com</a>.</p>
        </div>
        <div class="row center">
            <p> Want to learn more about managing your <a href="./">email marketing</a> and <a
                    href="autoresponders.htm">autoresponder</a> campaigns with AWeber? </p>
            <p><small>Copyright 1998-2020 AWeber Communications. All rights reserved.</small></p>
        </div>
    </main>
    
    </div>
  )
}

export default Permission
