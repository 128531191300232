import { Link } from "react-router-dom"
import exCaseStudy from "../../assets/images/ex-case-study.jpg"

const Blog = () => {
  return (
    <div>
      <div className="content case-study blog-home">
        <div className="wrapper">
          <div className="row">
            <div className="one-half column">
              <div className="tag">Case Study</div>
              <h2 className="art">NMDHB Anaesthetic department case study</h2>
              <p>
                At Nelson Marlborough DHB we used to generate the quarterly
                anaesthetic on-call roster by hand which took several days of
                work. Since switching to Kalivra Roster the whole process has
                been reduced to less than 60 minutes, and most of that is me
                gathering up the information needed from my colleagues.
              </p>
              <p>
                <Link to="/cs-nmdhb-june20">Read Full Case Study</Link  >
              </p>
            </div>
            <div className="one-half column">
              <img className="u-max-full-width" src={exCaseStudy} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="content latest-news">
        <div className="wrapper">
          <div className="row">
            <div className="two columns">&nbsp;</div>
            <div className="eight columns">
              <div className="tag">Article</div>
              <div className="postdate">13 Aug 2020</div>
              <h3>
                How to allocate public holidays without anyone complaining
                (TLDR; you can’t)
              </h3>
                <p>
                  Holidays, from the perspective of ‘the one who writes the
                  roster’. Public holidays are my spectre behind the wardrobe
                  door, or my monster under the bed. The allocated schedule of
                  names to dates has been written, rewritten, objected to and
                  argued with. Fortunately, to date, staff have always shown up
                  to work on the dates they were rostered to.
                </p>
                <p>
                  <Link to="/bl-holidays-aug20">Read full article</Link>
                </p>
            </div>
            <div className="two columns">&nbsp;</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Blog
