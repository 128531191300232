import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <footer>
            <nav>
                <ul id="myTopnav" className="topnav">
                    <li>
                        <Link to="/">Home</Link></li>
                    <li>
                        <Link to="/product">Product Info</Link></li>
                    <li>
                        <Link to="pricing">Pricing</Link></li>
                    <li>
                        <Link to="blog">Blog</Link></li>
                    <li>
                        <Link to="about-us">About Us</Link></li>
                    <li>
                        <Link to="careers">Careers</Link></li>
                    <li>
                        <Link to="support">Support</Link></li>
                    <li>
                        <Link to="contact-us">Contact Us</Link></li>
                </ul>
            </nav>

            <p className="copy">© 2020 Kalivra NZ Limited |
                <Link to="http://phpstack-109943-1285167.kalivra.com/terms.php">Terms &amp; Conditions</Link> |
                <Link to="http://phpstack-109943-1285167.kalivra.com/privacy.php">Privacy</Link>
            </p>
        </footer>
    )
}

export default Footer;