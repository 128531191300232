const Support = () => {
    return (
        <div className="content" id="supportc">


            <h2 style={{ textAlign: 'center' }}>Coming soon!</h2>

            <div style={{ display: 'none' }}>
                <div className="container">
                    <div className="row">
                        <div className="supb">
                            <h3>Getting Started</h3>
                            <div className="suppbx">
                                <ul>
                                    <li><a href="support-eg.html">Introduction</a></li>
                                    <li><a href="support-eg.html">System Requirements</a></li>
                                    <li><a href="support-eg.html">Installation Guide</a></li>
                                </ul>
                            </div>
                            <div className="suppbx">
                                <ul>
                                    <li><a href="support-eg.html">Developers Guide</a></li>
                                    <li><a href="support-eg.html">Billing</a></li>
                                    <li><a href="support-eg.html">Managing access levels</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="supb">
                            <h3>Help Guides</h3>
                            <div className="suppbx">
                                <ul>
                                    <li><a href="support-eg.html">Introduction</a></li>
                                    <li><a href="support-eg.html">System Requirements</a></li>
                                    <li><a href="support-eg.html">Installation Guide</a></li>
                                </ul>
                            </div>
                            <div className="suppbx">
                                <ul>
                                    <li><a href="support-eg.html">Adding staff memebers</a></li>
                                    <li><a href="support-eg.html">Adding rosters</a></li>
                                    <li><a href="support-eg.html">Managing access levels</a></li>
                                </ul>
                            </div>
                            <div className="suppbx">
                                <ul>
                                    <li><a href="support-eg.html">Editing staff leave</a></li>
                                    <li><a href="support-eg.html">Adding/editing preferred teams</a></li>
                                    <li><a href="support-eg.html">Troubleshooting</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="supb urgent">
                            <h3>For urgent support</h3>
                            <div className="suppbx">
                                <h4>Email: <a href="mailto:support@kalivra.com">support@kalivra.com</a></h4>
                            </div>
                            <div className="suppbx">
                                <h4>Phone: +64 3 538 0277</h4>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    );
}

export default Support;