import React from "react"
import "./App.css"
import Navbar from "./component/Navbar/Navbar"
import { Auth } from "aws-amplify"
import aws_exports from "./aws-exports"
import {
  // BrowserRouter as Router,
  Switch,
  Route,
  HashRouter,
  Redirect,
} from "react-router-dom"
import Header from "./component/Header/Header"
import Login from "./pages/Login/Login"
import SignupBeta from "./pages/SignupBeta/SignupBeta"
import Home from "./pages/Home/Home"
import Product from "./pages/Product/Product"
import Pricing from "./pages/Pricing/Pricing"
import Blog from "./pages/Blog/Blog"
import About from "./pages/About/About"
import Support from "./pages/Support/Support"
import Contact from "./pages/Contact/Contact"
import Careers from "./pages/Careers/Careers"
import Footer from "./component/Footer/Footer"
import Banner from "./component/Banner/Banner"
import Dashboard from "./pages/Dashboard/Dashboard"
import Signup from "./pages/Signup/Signup"
import Cs_nmdhb_june20 from "./pages/Cs-nmdhb-june20/Cs-nmdhb-june20"
import Bl_holidays_aug20 from "./pages/Bl-holidays-aug20/Bl-holidays-aug20"
import Permission from "./pages/Permission/Permission"
import { PageNotFound } from "./component/PageNotFound"

Auth.configure(aws_exports)

const App = () => {
  return (
    <div>
      <HashRouter>
        {/* <Router> */}
        <Switch>
          <Route exact path="/">
            <>
              <Header />
              <Navbar />
              <Banner bannerText="Home" />
              <Home />
              <Footer />
            </>
          </Route>
          <Route path="/product">
            <>
              <Header />
              <Navbar />
              <Banner bannerText="Smart features to ensure fast, effective rostering" />
              <Product />
              <Footer />
            </>
          </Route>
          <Route path="/pricing">
            <>
              <Header />
              <Navbar />
              <Banner bannerText="Pricing" />
              <Pricing />
              <Footer />
            </>
          </Route>
          <Route path="/blog">
            <>
              <Header />
              <Navbar />
              <Banner bannerText="Blog" />
              <Blog />
              <Footer />
            </>
          </Route>
          <Route path="/about-us">
            <>
              <Header />
              <Navbar />
              <Banner bannerText="About Us" />
              <About />
              <Footer />
            </>
          </Route>

          <Route path="/careers">
            <>
              <Header />
              <Navbar />
              <Banner bannerText="Careers at Kalivra" />
              <Careers />
              <Footer />
            </>
          </Route>
          <Route path="/support">
            <>
              <Header />
              <Navbar />
              <Banner bannerText="Support" />
              <Support />
              <Footer />
            </>
          </Route>
          <Route path="/contact-us">
            <>
              <Header />
              <Navbar />
              <Banner bannerText="Contact Us" />
              <Contact />
              <Footer />
            </>
          </Route>
          <Route path="/login">
            <>
              <Header />
              <Navbar />
              <Banner bannerText="Login to Kalivra Roster" />
              <Login />
              <Footer />
            </>
          </Route>
          <Route path="/signup">
            <>
              <Header />
              <Navbar />
              <Banner bannerText="Sign up to Kalivra Roster" />
              <Signup />
              <Footer />
            </>
          </Route>
          <Route path="/beta">
            <>
              <Header />
              <Navbar />
              <Banner bannerText="Sign up to Kalivra Roster" />
              <SignupBeta />
              <Footer />
            </>
          </Route>
          <Route path="/dashboard">
            <>
              <Header />
              <Navbar />
              <Dashboard />
              <Footer />
            </>
          </Route>
          <Route path="/cs-nmdhb-june20">
            <>
              <Header />
              <Navbar />
              <Banner bannerText="NMDHB Anaesthetic department case study" />
              <Cs_nmdhb_june20 />
              <Footer />
            </>
          </Route>

          <Route path="/bl-holidays-aug20">
            <>
              <Header />
              <Navbar />
              <Banner bannerText="How to allocate public holidays without anyone complaining (TLDR; you can’t)" />
              <Bl_holidays_aug20 />
              <Footer />
            </>
          </Route>
          <Route path="*">
            <>
              <PageNotFound />
            </>
          </Route>
        </Switch>

        <Route path="/permission">
          <>
            <Permission />
            <Footer />
          </>
        </Route>

        {/* <Redirect from="*" to="/404" /> */}
        {/* </Router> */}
      </HashRouter>
    </div>
  )
}

export default App
